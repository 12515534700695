.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: '#0095ff';
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: 'white';
}

.App-link {
  color: '#0095ff';
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

div.services-book::-webkit-scrollbar {
  height: 0.35rem;
}

div.services-book::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

div.services-book::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: hsl(205, 100%, 50%);
}

::-webkit-scrollbar {
  height: 0.35rem;
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: hsl(205, 100%, 50%);
}

.progress-box {
  width: 25% !important;
}

.checkout-icon {
  width: 50%;
  margin-left: auto;
  margin-right: 15%;
}

.checkout-icon-mini {
  width: 55%;
  margin-left: auto;
  margin-right: 15%;
  margin-bottom: 0;
}

.how-it-works-img {
  width: 100%;
  margin: auto;
  border-radius: 15px;
  margin-top: 0.5rem;
}

.header-logo {
  background-color: white;
  height: 100px;
  margin-top: 50%;
  position: relative;
  border-radius: 50%;
  border: none;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
}

.header-logo-dark {
  background-color: hsla(220, 0%, 0%, 0.7) !important;
}

.nav-buttons {
  margin-left: 10px !important;
}

.wrapper .wrapper-div {
  height: 40px;
  width: 40px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 50px;
  position: absolute;
  top: 10%;
  left: 10%;
  animation: animate 4s linear infinite;
}

.wrapper-div .dot {
  height: 8px;
  width: 8px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 20%;
  right: 20%;
}

.wrapper .wrapper-div:nth-child(1) {
  top: 20%;
  left: 20%;
  animation: animate 8s linear infinite;
}

.wrapper .wrapper-div:nth-child(2) {
  top: 60%;
  left: 60%;
  animation: animate 10s linear infinite;
}

.wrapper .wrapper-div:nth-child(3) {
  top: 40%;
  left: 40%;
  animation: animate 5s linear infinite;
}

.wrapper .wrapper-div:nth-child(4) {
  top: 66%;
  left: 30%;
  animation: animate 7s linear infinite;
}

.wrapper .wrapper-div:nth-child(5) {
  top: 90%;
  left: 10%;
  animation: animate 9s linear infinite;
}

.wrapper .wrapper-div:nth-child(6) {
  top: 30%;
  left: 60%;
  animation: animate 5s linear infinite;
}

.wrapper .wrapper-div:nth-child(7) {
  top: 70%;
  left: 20%;
  animation: animate 8s linear infinite;
}

.wrapper .wrapper-div:nth-child(8) {
  top: 75%;
  left: 60%;
  animation: animate 10s linear infinite;
}

.wrapper .wrapper-div:nth-child(9) {
  top: 50%;
  left: 50%;
  animation: animate 6s linear infinite;
}

.wrapper .wrapper-div:nth-child(10) {
  top: 45%;
  left: 20%;
  animation: animate 10s linear infinite;
}

.wrapper .wrapper-div:nth-child(11) {
  top: 10%;
  left: 90%;
  animation: animate 9s linear infinite;
}

.wrapper div:nth-child(12) {
  top: 20%;
  left: 70%;
  animation: animate 7s linear infinite;
}

.wrapper .wrapper-div:nth-child(13) {
  top: 20%;
  left: 20%;
  animation: animate 8s linear infinite;
}

.wrapper div:nth-child(14) {
  top: 60%;
  left: 5%;
  animation: animate 6s linear infinite;
}

.wrapper .wrapper-div:nth-child(15) {
  top: 90%;
  left: 80%;
  animation: animate 9s linear infinite;
}

@keyframes animate {
  0% {
    transform: scale(0) translateY(0) rotate(70deg);
  }

  100% {
    transform: scale(1.3) translateY(-100px) rotate(360deg);
  }
}

@media (min-width: 320px) {
  .progress-box {
    width: 75% !important;
  }

  .checkout-icon {
    width: 75%;
    margin-left: auto;
    margin-right: 5%;
  }

  .checkout-icon-mini {
    width: 85%;
    margin-right: auto;
  }

  .how-it-works-img {
    width: 100%;
    margin: auto;
    border-radius: 15px;
    margin-bottom: 1rem;
    margin-top: 0;
  }

  .header-logo {
    background-color: transparent;
    height: 60px;
    margin-top: 0;
    position: block;
    border-radius: 0;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .wrapper .wrapper-div {
    height: 25px;
    width: 25px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 50px;
    position: absolute;
    top: 10%;
    left: 10%;
    animation: animate 4s linear infinite;
  }

  .wrapper-div .dot {
    height: 5px;
    width: 5px;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 20%;
    right: 20%;
  }
}

@media (min-width: 480px) {
  .progress-box {
    width: 60% !important;
  }

  .checkout-icon {
    width: 60%;
    margin-left: auto;
    margin-right: 5%;
  }

  .checkout-icon-mini {
    width: 70%;
    margin-left: auto;
    margin-right: 5%;
  }

  .how-it-works-img {
    width: 100%;
    margin: auto;
    border-radius: 15px;
    margin-bottom: 0;
    margin-top: 0.5rem;
  }

  .header-logo {
    background-color: transparent;
    height: 60px;
    margin-top: 0;
    position: block;
    border-radius: 0;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .wrapper .wrapper-div {
    height: 40px;
    width: 40px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 50px;
    position: absolute;
    top: 10%;
    left: 10%;
    animation: animate 4s linear infinite;
  }

  .wrapper-div .dot {
    height: 8px;
    width: 8px;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 20%;
    right: 20%;
  }
}

@media (min-width: 600px) {
  .progress-box {
    width: 45% !important;
  }

  .checkout-icon {
    width: 50%;
    margin-left: auto;
    margin-right: 15%;
  }

  .checkout-icon-mini {
    width: 60%;
    margin-left: auto;
    margin-right: 15%;
  }

  .header-logo {
    background-color: white;
    height: 100px;
    margin-top: 50%;
    position: relative;
    border-radius: 50%;
    border: none;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  }

  .wrapper .wrapper-div {
    height: 40px;
    width: 40px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 50px;
    position: absolute;
    top: 10%;
    left: 10%;
    animation: animate 4s linear infinite;
  }

  .wrapper-div .dot {
    height: 8px;
    width: 8px;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 20%;
    right: 20%;
  }
}

@media (min-width: 801px) {
  .progress-box {
    width: 25% !important;
  }

  .checkout-icon {
    width: 50%;
    margin-left: auto;
    margin-right: 15%;
  }

  .checkout-icon-mini {
    width: 60%;
    margin-left: auto;
    margin-right: 15%;
  }

  .header-logo {
    background-color: white;
    height: 100px;
    margin-top: 50%;
    position: relative;
    border-radius: 50%;
    border: none;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  }
}